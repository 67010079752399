<template>
  <div class="home-page">
    <div class="content-box abs_center">
      <div class="text-container">
        <p>Hi,欢迎进入</p>
        <br>
        <p class="under">交发投资集团</p>
        <br>
        <p class="under">供应链服务平台！</p>
      </div>
      <img :src="logoUrl" alt="" class="image_logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      logoUrl: require('../../assets/home.png')
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.home-page {
  position: relative;
  width: 100%;
  height: 100% !important;
  background-color: #ffffff;
  overflow: hidden;
  .content-box {
    left: 48%;
    top: 48%;
    .text-container {
      position: absolute;
      left: -194px;
      top: 47px;
      p {
        display: inline-block;
        font-size: 30px;
        font-weight: 700;
        margin: 0;
        color: #2d363e;
        line-height: 45px;
      }
      p.under{
        position: relative;
        &::after{
          position: absolute;
          display: block;
          content: '';
          height: 6px;
          left: 0;
          right: 0;
          bottom: 3px;
          background-color: #2862e7;
          z-index: -1;
        }
      }
    }
  }
  .image_logo {
    height: 416px;
    width: 574px;
  }
  // .img-container{
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   background-image: url('../../assets/home.png');
  //   background-size: cover;
  //   background-repeat: no-repeat;
  // }
}
</style>
